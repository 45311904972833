import React from 'react';
import Layout from './components/Layout';
import './markdown.css'; // Make sure to create this file with the markdown styles

function App() {
  return (
    <Layout />
  );
}

export default App;