import React from 'react';
import { Sparkles } from 'lucide-react';

const ThemeSelector = ({ themes, currentTheme, setCurrentTheme }) => {
  return (
    <div className="relative">
      <select
        className={`appearance-none ${currentTheme.chatBg} border ${currentTheme.textColor} border-opacity-20 rounded-md p-2 pr-8 focus:outline-none focus:ring-2 ${currentTheme.accentColor}`}
        onChange={(e) => setCurrentTheme(themes.find(t => t.name === e.target.value))}
        value={currentTheme.name}
      >
        {themes.map((theme) => (
          <option key={theme.name} value={theme.name}>{theme.emoji} {theme.name}</option>
        ))}
      </select>
      <Sparkles className={`absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none ${currentTheme.textColor}`} size={16} />
    </div>
  );
};

export default ThemeSelector;