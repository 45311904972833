import React, { useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { Mail, Lock, LogIn, UserPlus } from 'lucide-react';

const Login = ({ currentTheme = {} }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isSignup, setIsSignup] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    chatBg = 'bg-emerald-100',
    textColor = 'text-emerald-900',
    accentColor = 'bg-emerald-600',
    sidebarBg = 'bg-emerald-800',
    sidebarText = 'text-emerald-100',
  } = currentTheme;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      if (isSignup) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          email: email,
          createdAt: new Date(),
        });
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      // Login/Signup successful, the Layout component will update automatically
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${chatBg} p-8 rounded-lg shadow-lg max-w-md w-full`}>
      <h2 className={`text-3xl font-bold mb-6 text-center ${textColor}`}>
        {isSignup ? 'Create Account' : 'Welcome Back'}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <Mail className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${textColor}`} size={20} />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={`w-full p-3 pl-10 rounded-md bg-white bg-opacity-20 focus:outline-none focus:ring-2 ${textColor} placeholder-opacity-70`}
            required
          />
        </div>
        <div className="relative">
          <Lock className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${textColor}`} size={20} />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className={`w-full p-3 pl-10 rounded-md bg-white bg-opacity-20 focus:outline-none focus:ring-2 ${textColor} placeholder-opacity-70`}
            required
          />
        </div>
        <button 
          type="submit"
          className={`w-full ${accentColor} ${sidebarText} p-3 rounded-md hover:opacity-90 transition-opacity flex items-center justify-center`}
          disabled={loading}
        >
          {loading ? (
            <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></span>
          ) : (
            <>
              {isSignup ? <UserPlus size={20} className="mr-2" /> : <LogIn size={20} className="mr-2" />}
              {isSignup ? 'Sign Up' : 'Login'}
            </>
          )}
        </button>
      </form>
      <button 
        onClick={() => setIsSignup(!isSignup)}
        className={`w-full mt-4 ${sidebarBg} ${sidebarText} p-3 rounded-md hover:opacity-90 transition-opacity`}
      >
        {isSignup ? 'Already have an account? Login' : 'Need an account? Sign Up'}
      </button>
      {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
    </div>
  );
};

export default Login;