// PersonalOasis.js
import React, { useState, useEffect } from 'react';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const PersonalOasis = ({ currentTheme, userId }) => {
  const [profile, setProfile] = useState({
    name: '',
    age: '',
    commonIssues: '',
  });

  useEffect(() => {
    const userRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setProfile(doc.data());
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userRef = doc(db, 'users', userId);
    try {
      await updateDoc(userRef, profile);
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  return (
    <div className={`p-6 ${currentTheme.chatBg} ${currentTheme.textColor}`}>
      <h2 className="text-2xl font-bold mb-4">Personal Oasis</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Name</label>
          <input
            type="text"
            name="name"
            value={profile.name || ''}
            onChange={handleInputChange}
            className={`w-full p-2 rounded ${currentTheme.textColor} bg-white bg-opacity-20`}
          />
        </div>
        <div>
          <label className="block mb-1">Age</label>
          <input
            type="number"
            name="age"
            value={profile.age || ''}
            onChange={handleInputChange}
            className={`w-full p-2 rounded ${currentTheme.textColor} bg-white bg-opacity-20`}
          />
        </div>
        <div>
          <label className="block mb-1">Common Issues (comma-separated)</label>
          <input
            type="text"
            name="commonIssues"
            value={profile.commonIssues || ''}
            onChange={handleInputChange}
            className={`w-full p-2 rounded ${currentTheme.textColor} bg-white bg-opacity-20`}
          />
        </div>
        <button type="submit" className={`${currentTheme.accentColor} text-white p-2 rounded-md hover:opacity-90`}>
          Save Profile
        </button>
      </form>
    </div>
  );
};

export default PersonalOasis;