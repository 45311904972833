import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, where, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const GoalTracker = ({ currentTheme, userId }) => {
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState('');

  useEffect(() => {
    fetchGoals();
  }, [userId]);

  const fetchGoals = async () => {
    const goalsQuery = query(collection(db, 'goals'), where('userId', '==', userId));
    const querySnapshot = await getDocs(goalsQuery);
    const fetchedGoals = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setGoals(fetchedGoals);
  };

  const addGoal = async () => {
    if (newGoal.trim() !== '') {
      try {
        const docRef = await addDoc(collection(db, 'goals'), {
          userId,
          text: newGoal,
          completed: false,
          createdAt: new Date()
        });
        setGoals([...goals, { id: docRef.id, text: newGoal, completed: false }]);
        setNewGoal('');
      } catch (error) {
        console.error('Error adding goal:', error);
      }
    }
  };

  const toggleGoal = async (goalId) => {
    try {
      const goalRef = doc(db, 'goals', goalId);
      const updatedGoal = goals.find(goal => goal.id === goalId);
      await updateDoc(goalRef, { completed: !updatedGoal.completed });
      setGoals(goals.map(goal => 
        goal.id === goalId ? { ...goal, completed: !goal.completed } : goal
      ));
    } catch (error) {
      console.error('Error toggling goal:', error);
    }
  };

  const deleteGoal = async (goalId) => {
    try {
      await deleteDoc(doc(db, 'goals', goalId));
      setGoals(goals.filter(goal => goal.id !== goalId));
    } catch (error) {
      console.error('Error deleting goal:', error);
    }
  };

  return (
    <div className={`${currentTheme.chatBg} p-6 rounded-lg shadow-lg`}>
      <h2 className={`text-2xl font-bold mb-4 ${currentTheme.textColor}`}>Goal Tracker</h2>
      <div className="mb-4">
        <input
          type="text"
          value={newGoal}
          onChange={(e) => setNewGoal(e.target.value)}
          placeholder="Enter a new goal"
          className={`w-full p-2 rounded-md ${currentTheme.textColor} bg-white bg-opacity-20`}
        />
        <button
          onClick={addGoal}
          className={`mt-2 ${currentTheme.accentColor} text-white px-4 py-2 rounded-md hover:opacity-90`}
        >
          Add Goal
        </button>
      </div>
      <ul>
        {goals.map((goal) => (
          <li key={goal.id} className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={goal.completed}
                onChange={() => toggleGoal(goal.id)}
                className="mr-2"
              />
              <span className={`${goal.completed ? 'line-through' : ''} ${currentTheme.textColor}`}>
                {goal.text}
              </span>
            </div>
            <button
              onClick={() => deleteGoal(goal.id)}
              className={`${currentTheme.accentColor} text-white px-2 py-1 rounded-md hover:opacity-90 text-sm`}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GoalTracker;