import React, { useState, useEffect } from 'react';

const affirmations = [
  "I am capable of achieving great things.",
  "I choose to be positive and optimistic.",
  "I am worthy of love and respect.",
  "I trust in my ability to overcome challenges.",
  "I embrace new opportunities for growth.",
  "I am grateful for all the good in my life.",
  "I radiate confidence and self-assurance.",
  "I am in charge of my own happiness.",
  "I choose peace and calmness in all situations.",
  "I am becoming the best version of myself every day."
];

const DailyAffirmation = ({ currentTheme }) => {
  const [affirmation, setAffirmation] = useState('');

  useEffect(() => {
    setAffirmation(affirmations[Math.floor(Math.random() * affirmations.length)]);
  }, []);

  return (
    <div className={`${currentTheme.chatBg} p-6 rounded-lg shadow-lg`}>
      <h2 className={`text-2xl font-bold mb-4 ${currentTheme.textColor}`}>Daily Affirmation</h2>
      <p className={`text-lg italic ${currentTheme.textColor}`}>"{affirmation}"</p>
    </div>
  );
};

export default DailyAffirmation;