import React, { useState, useEffect, useCallback, useRef } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, addDoc, query, where, onSnapshot, doc, setDoc, getDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Sidebar from './Sidebar';
import ThemeSelector from './ThemeSelector';
import BreathingExercise from './BreathingExercise';
import MindfulnessTips from './MindfulnessTips';
import MoodLogger from './MoodLogger';
import DailyAffirmation from './DailyAffirmation';
import GoalTracker from './GoalTracker';
import MoodTracker from './MoodTracker';
import PersonalOasis from './PersonalOasis';
import openaiService from '../services/openai';
import { Menu, Send, LogOut } from 'lucide-react';
import Login from './Login';

// Themes and Journey Names
const themes = [
  {
    name: 'Forest Serenity',
    class: 'bg-emerald-50',
    accentColor: 'bg-emerald-600',
    textColor: 'text-emerald-900',
    sidebarBg: 'bg-emerald-800',
    sidebarText: 'text-emerald-100',
    chatBg: 'bg-emerald-100',
    emoji: '🌿',
  },
  {
    name: 'Ocean Calm',
    class: 'bg-sky-50',
    accentColor: 'bg-sky-600',
    textColor: 'text-sky-900',
    sidebarBg: 'bg-sky-800',
    sidebarText: 'text-sky-100',
    chatBg: 'bg-sky-100',
    emoji: '🌊',
  },
  {
    name: 'Desert Peace',
    class: 'bg-amber-50',
    accentColor: 'bg-amber-600',
    textColor: 'text-amber-900',
    sidebarBg: 'bg-amber-800',
    sidebarText: 'text-amber-100',
    chatBg: 'bg-amber-100',
    emoji: '🏜️',
  },
];

const journeyNames = [
  "Serenity Path", "Mindful Meadow", "Tranquil Trail", "Harmony Heights",
  "Peaceful Plateau", "Calm Creek", "Zen Zone", "Blissful Bay",
  "Quiet Quest", "Soothing Summit", "Relaxation Ridge", "Inner Peace Path",
];

// Updated Chat component
const Chat = ({ currentTheme, messages, inputMessage, setInputMessage, handleSendMessage }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.sender === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[70%] rounded-lg p-3 ${
                message.sender === 'user'
                  ? `${currentTheme.accentColor} text-white`
                  : 'bg-white'
              }`}
            >
              <p className={message.sender === 'user' ? 'text-white' : currentTheme.textColor}>
                {message.text}
              </p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

const Layout = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentTheme, setCurrentTheme] = useState(themes[0]);
  const [activeComponent, setActiveComponent] = useState('chat');
  const [chatSessions, setChatSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userProfile, setUserProfile] = useState({
    name: '',
    age: '', 
    commonIssues: '',
    profileImage: null,
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      if (currentUser) {
        try {
          // Fetch user profile
          const userRef = doc(db, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserProfile(userSnap.data());
          } else {
            // If user document doesn't exist, create it
            const newUserProfile = {
              email: currentUser.email,
              createdAt: new Date(),
            };
            await setDoc(userRef, newUserProfile);
            setUserProfile(newUserProfile);
          }

          // Fetch chat sessions
          const sessionsQuery = query(collection(db, `users/${currentUser.uid}/chatSessions`));
          const unsubscribeSessions = onSnapshot(sessionsQuery, (snapshot) => {
            const sessions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChatSessions(sessions);
            if (sessions.length > 0 && !currentSessionId) {
              setCurrentSessionId(sessions[0].id);
            }
          });

          return () => unsubscribeSessions();
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data. Please try again.');
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && currentSessionId) {
      const messagesQuery = query(collection(db, `users/${user.uid}/chatSessions/${currentSessionId}/messages`));
      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messageList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.timestamp.toDate() - b.timestamp.toDate());
        setMessages(messageList);
      });

      return () => unsubscribe();
    }
  }, [user, currentSessionId]);

  const startNewChat = useCallback(async () => {
    if (!user) return;

    try {
      const journeyName = journeyNames[Math.floor(Math.random() * journeyNames.length)];
      const sessionRef = await addDoc(collection(db, `users/${user.uid}/chatSessions`), {
        name: journeyName,
        createdAt: new Date(),
      });

      const defaultMessage = {
        text: `Hello${userProfile.name ? ` ${userProfile.name}` : ''}! Welcome to your ${journeyName}. I'm your AI personal therapist, here to listen, support, and guide you through any challenges you might be facing. How are you feeling today?`,
        sender: 'ai',
        timestamp: new Date(),
      };

      await addDoc(collection(db, `users/${user.uid}/chatSessions/${sessionRef.id}/messages`), defaultMessage);

      setCurrentSessionId(sessionRef.id);
      setActiveComponent('chat');
      setIsSidebarOpen(false);
    } catch (error) {
      console.error('Error starting new chat:', error);
      setError('Failed to start a new chat. Please try again.');
    }
  }, [user, userProfile.name]);

  useEffect(() => {
    if (user && chatSessions.length === 0) {
      startNewChat();
    }
  }, [user, chatSessions.length, startNewChat]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '' || !user || !currentSessionId) return;
    const userMessage = { text: inputMessage, sender: 'user', timestamp: new Date() };
    setInputMessage('');

    try {
      await addDoc(collection(db, `users/${user.uid}/chatSessions/${currentSessionId}/messages`), userMessage);

      const aiResponse = await openaiService.generateResponse([...messages, userMessage], userProfile);
      
      await addDoc(collection(db, `users/${user.uid}/chatSessions/${currentSessionId}/messages`), {
        text: aiResponse,
        sender: 'ai',
        timestamp: new Date(),
      });
    } catch (error) {
      console.error('Error sending/receiving message:', error);
      setError('Failed to send or receive message. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setChatSessions([]);
      setCurrentSessionId(null);
      setMessages([]);
      setUserProfile({
        name: '',
        age: '', 
        commonIssues: '',
        profileImage: null,
      });
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  const removeConversation = useCallback(async (sessionId) => {
    if (!user) return;

    try {
      // Delete the chat session document
      await deleteDoc(doc(db, `users/${user.uid}/chatSessions`, sessionId));

      // Delete all messages associated with this session
      const messagesQuery = query(collection(db, `users/${user.uid}/chatSessions/${sessionId}/messages`));
      const messagesSnapshot = await getDocs(messagesQuery);
      const deletePromises = messagesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      // Update local state
      setChatSessions(prevSessions => prevSessions.filter(session => session.id !== sessionId));
      if (currentSessionId === sessionId) {
        setCurrentSessionId(null);
        setMessages([]);
      }

      alert('Conversation removed successfully');
    } catch (error) {
      console.error('Error removing conversation:', error);
      setError('Failed to remove conversation. Please try again.');
    }
  }, [user, currentSessionId]);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'chat':
        return (
          <Chat
            currentTheme={currentTheme}
            messages={messages}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
          />
        );
      case 'breathingExercise':
        return <BreathingExercise currentTheme={currentTheme} />;
      case 'mindfulnessTips':
        return <MindfulnessTips currentTheme={currentTheme} />;
      case 'moodLogger':
        return <MoodLogger currentTheme={currentTheme} userId={user.uid} />;
      case 'dailyAffirmation':
        return <DailyAffirmation currentTheme={currentTheme} />;
      case 'goalTracker':
        return <GoalTracker currentTheme={currentTheme} userId={user.uid} />;
      case 'moodTracker':
        return <MoodTracker currentTheme={currentTheme} userId={user.uid} />;
      case 'settings':
        return <PersonalOasis currentTheme={currentTheme} userProfile={userProfile} setUserProfile={setUserProfile} userId={user.uid} />;
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <div className={`h-screen w-screen flex flex-col justify-center items-center ${currentTheme.class}`}>
        <Login />
      </div>
    );
  }

  return (
    <div className={`h-screen w-screen flex flex-col overflow-hidden ${currentTheme.class}`}>
      <header className={`${currentTheme.sidebarBg} shadow-sm p-4 flex justify-between items-center z-20 fixed top-0 left-0 right-0`}>
        <div className="flex items-center">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className={`mr-4 ${currentTheme.sidebarText} hover:opacity-80`}
          >
            <Menu size={24} />
          </button>
          <h1 className={`text-xl md:text-3xl font-serif font-bold ${currentTheme.sidebarText}`}>Harmony Haven</h1>
        </div>
        <div className="flex items-center">
          <ThemeSelector themes={themes} currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
          <button
            onClick={handleLogout}
            className={`ml-4 ${currentTheme.sidebarText} hover:opacity-80`}
          >
            <LogOut size={24} />
          </button>
        </div>
      </header>
      <div className="flex-1 flex overflow-hidden relative pt-16">
        <div
          className={`
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
            fixed inset-y-0 left-0 z-30 w-64 transition-transform duration-300 ease-in-out 
            md:relative md:translate-x-0
          `}
        >
          <Sidebar
            currentTheme={currentTheme}
            setActiveComponent={setActiveComponent}
            startNewChat={startNewChat}
            chatSessions={chatSessions}
            currentSessionId={currentSessionId}
            setCurrentSessionId={setCurrentSessionId}
            closeSidebar={() => setIsSidebarOpen(false)}
            removeConversation={removeConversation}
          />
        </div>
        {isSidebarOpen && (
          <div
            className="absolute inset-0 bg-black bg-opacity-50 z-20 md:hidden"
            onClick={() => setIsSidebarOpen(false)}
          ></div>
        )}
        <div className="flex-1 flex flex-col">
          <main className={`flex-1 overflow-y-auto ${currentTheme.chatBg} pb-16`}>
            {error && (
              <div className={`${currentTheme.accentColor} text-white p-2 text-center`}>
                {error}
              </div>
            )}
            {renderActiveComponent()}
          </main>
          {activeComponent === 'chat' && (
            <footer className={`fixed bottom-0 left-0 md:left-64 right-0 p-4 ${currentTheme.chatBg} bg-opacity-90`}>
              <div className="flex items-center bg-white rounded-full p-2 shadow-lg">
                <input
                  type="text"
                  placeholder="Share your thoughts..."
                  className={`flex-1 bg-transparent border-none focus:ring-0 ${currentTheme.textColor} placeholder-opacity-50`}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                />
                <button
                  onClick={handleSendMessage}
                  className={`${currentTheme.accentColor} text-white p-2 rounded-full hover:opacity-90`}
                >
                  <Send size={20} />
                </button>
              </div>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;