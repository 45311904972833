import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const breathingTechniques = [
  {
    name: "Box Breathing",
    description: "Used by Navy SEALs for stress relief and focus",
    pattern: [
      { phase: "Inhale", duration: 4 },
      { phase: "Hold", duration: 4 },
      { phase: "Exhale", duration: 4 },
      { phase: "Hold", duration: 4 }
    ]
  },
  {
    name: "4-7-8 Technique",
    description: "Promotes relaxation and better sleep",
    pattern: [
      { phase: "Inhale", duration: 4 },
      { phase: "Hold", duration: 7 },
      { phase: "Exhale", duration: 8 }
    ]
  },
  {
    name: "Diaphragmatic Breathing",
    description: "Reduces stress and lowers heart rate",
    pattern: [
      { phase: "Inhale deeply", duration: 4 },
      { phase: "Exhale slowly", duration: 6 }
    ]
  }
];

const BreathingExercise = ({ currentTheme }) => {
  const [selectedTechnique, setSelectedTechnique] = useState(breathingTechniques[0]);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
  const [count, setCount] = useState(selectedTechnique.pattern[0].duration);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        const nextPhaseIndex = (currentPhaseIndex + 1) % selectedTechnique.pattern.length;
        setCurrentPhaseIndex(nextPhaseIndex);
        setCount(selectedTechnique.pattern[nextPhaseIndex].duration);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [count, currentPhaseIndex, selectedTechnique]);

  const circleVariants = {
    inhale: { scale: 1.5, transition: { duration: 4, ease: "easeInOut" } },
    hold: { scale: 1.5, transition: { duration: 4, ease: "linear" } },
    exhale: { scale: 1, transition: { duration: 4, ease: "easeInOut" } }
  };

  return (
    <div className={`${currentTheme.chatBg} p-6 rounded-lg shadow-lg flex flex-col items-center`}>
      <h2 className={`text-2xl font-bold mb-4 ${currentTheme.textColor}`}>Breath of Life</h2>
      <select
        className={`mb-4 p-2 rounded ${currentTheme.textColor} bg-white bg-opacity-20`}
        onChange={(e) => setSelectedTechnique(breathingTechniques[e.target.value])}
      >
        {breathingTechniques.map((technique, index) => (
          <option key={index} value={index}>{technique.name}</option>
        ))}
      </select>
      <p className={`mb-4 text-center ${currentTheme.textColor}`}>{selectedTechnique.description}</p>
      <motion.div
        className={`w-64 h-64 rounded-full ${currentTheme.accentColor} flex items-center justify-center`}
        variants={circleVariants}
        animate={selectedTechnique.pattern[currentPhaseIndex].phase.toLowerCase().includes('inhale') ? 'inhale' : 'exhale'}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={`${currentPhaseIndex}-${count}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-white text-3xl font-bold text-center"
          >
            <div>{selectedTechnique.pattern[currentPhaseIndex].phase}</div>
            <div className="text-5xl mt-2">{count}</div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default BreathingExercise;