import React from 'react';
import { Plus, MessageSquare, Activity, Wind, Brain, Settings, Heart, Target, Sun, X, Trash2 } from 'lucide-react';

const Sidebar = ({ 
  currentTheme, 
  setActiveComponent, 
  startNewChat, 
  chatSessions, 
  currentSessionId, 
  setCurrentSessionId, 
  closeSidebar,
  removeConversation
}) => {
  const menuItems = [
    { icon: Activity, label: 'Mood Garden', component: 'moodLogger' },
    { icon: Wind, label: 'Breath of Life', component: 'breathingExercise' },
    { icon: Brain, label: 'Mindful Moments', component: 'mindfulnessTips' },
    { icon: Heart, label: 'Daily Affirmation', component: 'dailyAffirmation' },
    { icon: Target, label: 'Goal Tracker', component: 'goalTracker' },
    { icon: Sun, label: 'Mood History', component: 'moodTracker' },
    { icon: Settings, label: 'Personal Oasis', component: 'settings' },
  ];

  return (
    <div className={`h-full ${currentTheme.sidebarBg} ${currentTheme.sidebarText} p-4 flex flex-col`}>
      <div className="flex justify-between items-center mb-6 md:hidden">
        <h2 className="text-lg font-semibold">Menu</h2>
        <button onClick={closeSidebar} className="hover:opacity-80">
          <X size={24} />
        </button>
      </div>
      <button 
        onClick={() => { startNewChat(); closeSidebar(); }}
        className={`${currentTheme.accentColor} text-white p-3 rounded-md hover:opacity-90 flex items-center justify-center mb-6`}
      >
        <Plus size={20} className="mr-2" />
        <span>New Journey</span>
      </button>
      <div className="flex-1 overflow-y-auto mb-6">
        <h2 className="text-lg font-semibold mb-2">Chat Sessions</h2>
        {chatSessions.map(session => (
          <div 
            key={session.id} 
            className={`p-3 rounded-md mb-2 cursor-pointer flex justify-between items-center ${
              session.id === currentSessionId ? 'bg-white bg-opacity-20' : 'hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <div
              onClick={() => {
                setCurrentSessionId(session.id);
                setActiveComponent('chat');
                closeSidebar();
              }}
              className="flex-grow flex items-center"
            >
              <MessageSquare size={16} className="mr-2" />
              <span className="truncate">{session.name}</span>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm('Are you sure you want to remove this conversation?')) {
                  removeConversation(session.id);
                }
              }}
              className="text-red-500 hover:text-red-700 ml-2"
            >
              <Trash2 size={16} />
            </button>
          </div>
        ))}
      </div>
      <div className="space-y-3">
        <h2 className="text-lg font-semibold mb-2">Wellness Tools</h2>
        {menuItems.map(({ icon: Icon, label, component }) => (
          <button 
            key={label} 
            className="w-full p-3 rounded-md hover:bg-white hover:bg-opacity-10 flex items-center"
            onClick={() => { setActiveComponent(component); closeSidebar(); }}
          >
            <Icon size={16} className="mr-3" />
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;