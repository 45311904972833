import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const MoodTracker = ({ currentTheme, userId }) => {
  const [moodData, setMoodData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const fetchMoodData = async () => {
      setLoading(true);
      setError(null);
      try {
        const userDocRef = doc(db, 'users', userId);
        const moodEntriesCollectionRef = collection(userDocRef, 'moodEntries');
        const moodQuery = query(
          moodEntriesCollectionRef,
          orderBy('timestamp', 'desc'),
          limit(7)
        );
        const querySnapshot = await getDocs(moodQuery);
        const moodEntries = querySnapshot.docs.map(doc => ({
          id: doc.id,
          date: doc.data().timestamp.toDate().toLocaleDateString(),
          mood: doc.data().mood,
        }));
        setMoodData(moodEntries.reverse());
        setLoading(false);
      } catch (err) {
        console.error('Error fetching mood data:', err);
        if (err.message.includes('requires an index')) {
          setError('We\'re setting up the mood tracker. This may take a few minutes. Please try again soon.');
          if (retryCount < 3) {
            setTimeout(() => {
              setRetryCount(prevCount => prevCount + 1);
            }, 10000); // Retry after 10 seconds
          } else {
            setError('Unable to load mood data. Please try again later or contact support.');
          }
        } else {
          setError('Failed to load mood data. Please try again later.');
        }
        setLoading(false);
      }
    };

    fetchMoodData();
  }, [userId, retryCount]);

  const moodEmoji = (mood) => {
    const emojis = ['😢', '😕', '😐', '🙂', '😄'];
    return emojis[mood - 1];
  };

  if (loading) {
    return <div className={`p-6 ${currentTheme.chatBg}`}>Loading mood data...</div>;
  }

  if (error) {
    return (
      <div className={`p-6 ${currentTheme.chatBg}`}>
        <p className="text-red-500">{error}</p>
        {retryCount < 3 && (
          <button
            onClick={() => setRetryCount(prevCount => prevCount + 1)}
            className={`mt-4 ${currentTheme.accentColor} text-white p-2 rounded-md hover:opacity-90`}
          >
            Retry
          </button>
        )}
      </div>
    );
  }

  return (
    <div className={`p-6 rounded-lg shadow-lg ${currentTheme.chatBg}`}>
      <h2 className={`text-2xl font-bold mb-4 ${currentTheme.textColor}`}>Mood History</h2>
      {moodData.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={moodData}>
            <XAxis 
              dataKey="date" 
              stroke={currentTheme.textColor} 
              tick={{ fill: currentTheme.textColor }}
            />
            <YAxis 
              domain={[1, 5]} 
              stroke={currentTheme.textColor}
              tick={{ fill: currentTheme.textColor }}
              tickFormatter={(value) => moodEmoji(value)}
            />
            <Tooltip
              contentStyle={{ 
                backgroundColor: currentTheme.sidebarBg, 
                border: 'none', 
                borderRadius: '8px', 
                color: currentTheme.sidebarText 
              }}
              formatter={(value) => [`Mood: ${moodEmoji(value)}`, '']}
              labelStyle={{ color: currentTheme.sidebarText }}
            />
            <Line 
              type="monotone" 
              dataKey="mood" 
              stroke={currentTheme.accentColor} 
              strokeWidth={3}
              dot={{ 
                r: 6, 
                strokeWidth: 2, 
                fill: currentTheme.chatBg,
                stroke: currentTheme.accentColor 
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p className={`${currentTheme.textColor}`}>No mood data available. Start logging your moods!</p>
      )}
    </div>
  );
};

export default MoodTracker;