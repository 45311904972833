import React, { useState, useEffect } from 'react';

const tips = [
  "Take a moment to focus on your breath.",
  "Notice five things you can see around you.",
  "Listen for three distinct sounds in your environment.",
  "Feel the sensation of your feet touching the ground.",
  "Acknowledge your thoughts without judgment.",
  "Scan your body for any areas of tension and try to relax them.",
  "Practice gratitude by thinking of three things you're thankful for.",
  "Engage your senses fully in a simple activity, like drinking water.",
  "Observe the present moment, just as it is.",
  "Take a mindful walk, paying attention to each step."
];

const MindfulnessTips = ({ currentTheme }) => {
  const [currentTip, setCurrentTip] = useState('');

  useEffect(() => {
    setCurrentTip(tips[Math.floor(Math.random() * tips.length)]);
  }, []);

  const getNewTip = () => {
    let newTip;
    do {
      newTip = tips[Math.floor(Math.random() * tips.length)];
    } while (newTip === currentTip);
    setCurrentTip(newTip);
  };

  return (
    <div className={`${currentTheme.chatBg} p-6 rounded-lg shadow-lg`}>
      <h2 className={`text-2xl font-bold mb-4 ${currentTheme.textColor}`}>Mindfulness Tip</h2>
      <p className={`text-lg mb-4 ${currentTheme.textColor}`}>{currentTip}</p>
      <button
        onClick={getNewTip}
        className={`${currentTheme.accentColor} text-white px-4 py-2 rounded-md hover:opacity-90`}
      >
        New Tip
      </button>
    </div>
  );
};

export default MindfulnessTips;