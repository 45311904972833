import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAHMGtBRwm4zS-QOwjUtNZc1i5Tqr6WPYQ",
    authDomain: "aitherapy-89ac9.firebaseapp.com",
    projectId: "aitherapy-89ac9",
    storageBucket: "aitherapy-89ac9.appspot.com",
    messagingSenderId: "663442217167",
    appId: "1:663442217167:web:d7ac6abed67c89ece13fba"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
