import React, { useState } from 'react';
import { addDoc, collection, doc } from 'firebase/firestore';
import { db } from '../firebase';

const moods = ['😢', '😕', '😐', '🙂', '😄'];

const MoodLogger = ({ currentTheme, userId }) => {
  const [selectedMood, setSelectedMood] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleMoodSelect = async (index) => {
    setSelectedMood(index);
    setLoading(true);
    setError(null);
    const moodValue = index + 1; // Mood values from 1 to 5
    
    try {
      const userDocRef = doc(db, 'users', userId);
      const moodEntriesCollectionRef = collection(userDocRef, 'moodEntries');
      
      await addDoc(moodEntriesCollectionRef, {
        mood: moodValue,
        timestamp: new Date()
      });
      
      setLoading(false);
      alert('Mood logged successfully!');
    } catch (error) {
      console.error('Error logging mood:', error);
      setError('Failed to log mood. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className={`${currentTheme.chatBg} p-6 rounded-lg shadow-lg`}>
      <h2 className={`text-2xl font-bold mb-4 ${currentTheme.textColor}`}>How are you feeling?</h2>
      <div className="flex justify-between">
        {moods.map((mood, index) => (
          <button
            key={index}
            onClick={() => handleMoodSelect(index)}
            className={`text-4xl ${selectedMood === index ? 'transform scale-125' : ''} transition-transform`}
            disabled={loading}
          >
            {mood}
          </button>
        ))}
      </div>
      {loading && <p className={`mt-4 ${currentTheme.textColor}`}>Logging mood...</p>}
      {error && <p className={`mt-4 text-red-500`}>{error}</p>}
      {selectedMood !== null && !loading && !error && (
        <p className={`mt-4 text-center ${currentTheme.textColor}`}>
          You selected: {moods[selectedMood]}
        </p>
      )}
    </div>
  );
};

export default MoodLogger;